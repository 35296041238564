import BVStyleguide from '@bv/styleguide';
import React, { SetStateAction, useEffect, useState } from 'react';
import { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
import { Badge, Checkbox, EInput, FormInput, Label, Loader } from '@eyecarehealth/ds-aquilae-react';
import { Address, ViaCepAddress } from '../../../../../types/v2/global';

type FormAddressProps = {
  methods: any;
  address: Omit<Address, 'number' | 'country' | 'complement'>;
  setAddress: React.Dispatch<SetStateAction<Omit<Address, 'number' | 'country' | 'complement'> | undefined>>;
};

export function FormAddress({ methods, setAddress, address }: FormAddressProps) {
  const [isAddressNotFound, setIsAddressNotFound] = useState(false);
  const [isZipCodeNotFound, canEditAddressFields] = useState(false);
  const [isCEPSearchLoading, setIsCEPSearchLoading] = useState(false);
  const zipCodeWatch = methods.watch('zipCode');

  async function handleSelectAddress(address?: any) {
    if (!address) {
      methods.setValue('complement', '');
      methods.setValue('number', '');
      methods.setValue('zipCode', '');
      methods.setValue('street', '');
      methods.setValue('city', '');
      methods.setValue('state', '');
      methods.setValue('neighbourhood', '');
      setAddress(undefined);
      return;
    }

    try {
      const results = await geocodeByAddress(address.description);
      console.log({ results });
      const latLng = await getLatLng(results[0]);
      const streetNumber = results[0].address_components.find((address) => address.types.some((type) => type === 'street_number'));
      const street = results[0].address_components.find((address) => address.types.some((type) => ['route', 'sublocality_level_3'].includes(type)));
      const city = results[0].address_components.find((address) => address.types.some((type) => type === 'administrative_area_level_2'));
      const state = results[0].address_components.find((address) => address.types.some((type) => type === 'administrative_area_level_1'));
      const neighbourhood = results[0].address_components.find((address) => address.types.some((type) => type === 'sublocality'));
      const zipCode = results[0].address_components.find((address) => address.types.some((type) => type === 'postal_code'));

      if (streetNumber) methods.setValue('number', streetNumber.long_name);

      if (zipCode) methods.setValue('zipCode', zipCode.long_name);
      if (street) methods.setValue('street', street.long_name);
      if (city) methods.setValue('city', city.long_name);
      if (state) methods.setValue('state', state.long_name);
      if (neighbourhood) methods.setValue('neighbourhood', neighbourhood.long_name);

      setAddress({
        street: street?.long_name || '',
        city: city?.long_name || '',
        state: state?.long_name || '',
        neighbourhood: neighbourhood?.long_name || '',
        zipCode: zipCode?.long_name || '',
        placeId: results[0].place_id,
        location: {
          type: 'Point',
          coordinates: [latLng.lng, latLng.lat],
        },
      });
    } catch (error) {
      console.error('Error', error);
    }
  }

  useEffect(() => {
    if (isAddressNotFound) {
      methods.setValue('street', '');
      methods.setValue('city', '');
      methods.setValue('state', '');
      methods.setValue('neighbourhood', '');
      methods.setValue('zipCode', '');
    }
  }, [isAddressNotFound]);

  useEffect(() => {
    const zipCode = zipCodeWatch?.replace(/[^0-9]/g, '');
    console.log({ zipCode });

    async function getAddressFromZipCode() {
      try {
        setIsCEPSearchLoading(true);
        const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
        const data: ViaCepAddress = await response.json();
        console.log({ data });

        methods.setValue('street', data.logradouro);
        methods.setValue('neighbourhood', data.bairro);
        methods.setValue('city', data.localidade);
        methods.setValue('state', data.uf);
      } catch (error) {
        console.error('Error', error);
      } finally {
        canEditAddressFields(true);
        setIsCEPSearchLoading(false);
      }
    }

    if (zipCode?.length === 8 && isAddressNotFound) {
      getAddressFromZipCode();
    }
  }, [zipCodeWatch, isAddressNotFound]);

  return (
    <div>
      <div className="mb-6 flex w-full items-center gap-2">
        <Checkbox onCheckedChange={(value) => setIsAddressNotFound(value as boolean)} checked={isAddressNotFound} />
        <Label>Marque a caixa de seleção se não conseguir encontrar seu endereço.</Label>
      </div>
      {!isAddressNotFound && (
        <div className="mb-4 flex flex-col gap-2 md:flex-row">
          <div className="w-full">
            <BVStyleguide.AddressAutocomplete handleSelectAddress={handleSelectAddress} />
          </div>
        </div>
      )}
      {!isAddressNotFound && <AddressFieldsForGoogleAutocomplete address={address} />}
      {isAddressNotFound && <AddressFieldsForZipCode isZipCodeNotFound={isZipCodeNotFound} isCEPSearchLoading={isCEPSearchLoading} />}
    </div>
  );
}

function AddressFieldsForGoogleAutocomplete({ address }: { address: Omit<Address, 'number' | 'country' | 'complement'> }) {
  return (
    <div>
      <div className="flex flex-col gap-2 md:flex-row">
        <div className="w-full md:w-2/4">
          <FormInput
            label="Número"
            name="streetNumber"
            minValue={1}
            description="Não preencha se não houver número"
            placeholder="Digite o número"
            type={EInput.NUMBER}
          />
        </div>
        <div className="w-full md:w-2/4">
          <FormInput label="Complemento (opcional)" name="complement" placeholder="Complemento" type={EInput.TEXT} />
        </div>
      </div>
      {address?.street && (
        <>
          <FormInput label="CEP" name="zipCode" mask="#####-###" placeholder="Digite o CEP" type={EInput.MASK} />
          <div className="flex gap-2">
            <FormInput label="Rua" name="street" placeholder="Digite a rua" type={EInput.TEXT} />
            <FormInput label="Bairro" name="neighbourhood" placeholder="Digite o bairro" type={EInput.TEXT} />
          </div>
          <div className="flex gap-2">
            <FormInput disabled={!!address?.city} label="Cidade" name="city" placeholder="Digite a cidade" type={EInput.TEXT} />
            <FormInput disabled={!!address?.state} label="Estado" name="state" placeholder="Digite o estado" type={EInput.TEXT} />
          </div>
        </>
      )}
    </div>
  );
}

function AddressFieldsForZipCode({ isZipCodeNotFound, isCEPSearchLoading }: { isZipCodeNotFound: boolean; isCEPSearchLoading: boolean }) {
  return (
    <div>
      <div className="md:flex md:gap-2">
        <div className="relative w-full">
          <FormInput label="CEP" name="zipCode" mask="#####-###" placeholder="Digite o CEP" type={EInput.MASK} />
          {isCEPSearchLoading && <Loader className="absolute right-2 top-[40px] size-4" />}
        </div>
        <FormInput disabled={!isZipCodeNotFound} label="Bairro" name="neighbourhood" placeholder="Digite o bairro" type={EInput.TEXT} />
      </div>
      <FormInput disabled={!isZipCodeNotFound} label="Rua" name="street" placeholder="Digite a rua" type={EInput.TEXT} />
      <div className="md:flex md:gap-2">
        <div className="w-full md:w-1/4">
          <FormInput
            label="Número"
            name="streetNumber"
            minValue={1}
            description="Não preencha se não houver número"
            placeholder="Digite o número"
            type={EInput.NUMBER}
          />
        </div>
        <div className="w-full md:w-3/4">
          <FormInput label="Complemento (opcional)" name="complement" placeholder="Complemento" type={EInput.TEXT} />
        </div>
      </div>
      <div className="md:flex md:gap-2">
        <FormInput disabled={!isZipCodeNotFound} label="Cidade" name="city" placeholder="Digite a cidade" type={EInput.TEXT} />
        <FormInput disabled={!isZipCodeNotFound} label="Estado" name="state" placeholder="Digite o estado" type={EInput.TEXT} />
      </div>
    </div>
  );
}
