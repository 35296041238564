import BVStyleguide from '@bv/styleguide';
import { Button, Loader } from '@eyecarehealth/ds-aquilae-react';
import { GET_USER_PRE_REGISTER_DATA } from '@public-registration/constants/queries';
import { routeAnimationVariants } from '@public-registration/router';
import { getUserPreRegisterService } from '@public-registration/services/auth.service';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtomValue, useSetAtom } from 'jotai';
import { ConstructionIcon, PackageOpenIcon } from 'lucide-react';
import { RegisterStep, registerStepAtom, userPreRegisterDataAtom } from '../../atom/auth.atom';
import { AddressForm } from './components/AddressForm';
import { PasswordForm } from './components/PasswordForm';
import { UserInfoForm } from './components/UserInfoForm';

export default function RegistrationPage() {
  const setUserPreRegisterData = useSetAtom(userPreRegisterDataAtom);
  const registerStep = useAtomValue<RegisterStep>(registerStepAtom);
  const searchParams = new URLSearchParams(window.location.search);

  const userId = searchParams.get('_i');
  const otp = searchParams.get('otp');

  const {
    data: userPreRegisterData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [GET_USER_PRE_REGISTER_DATA],
    queryFn: () => getUserPreRegisterService(userId!, { otp: otp! }),
    select: (data) => {
      if (data.name) setUserPreRegisterData({ ...data });
    },
    enabled: !!userId,
    retry: false,
  });

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center p-2">
        <Loader text="Carregando dados..." />
      </div>
    );
  }

  console.log({ error });

  if (isError) {
    return (
      <motion.div
        className="flex h-full flex-col items-center justify-center gap-4"
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 20,
        }}
        key="identifier"
        exit="exit"
        animate="final"
        initial="initial"
        variants={routeAnimationVariants}>
        <BVStyleguide.Feedback
          className="w-full"
          icon={<ConstructionIcon size={24} className="text-typography-secondary" />}
          title="Não podemos prosseguir."
          description={(error as unknown as string) || 'Não foi possível carregar as informações do usuário.'}
          variant="warning"
        />
        {(error as unknown as string) === 'Usuário já está ativo' && (
          <Button variant="secondary" onClick={() => (window.location.href = '/login')}>
            Ir para login
          </Button>
        )}
      </motion.div>
    );
  }

  // TODO: Temporiamente desativar verificacao de otp
  // if (!userId || !otp) {
  if (!userId) {
    return (
      <motion.div
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 20,
        }}
        key="identifier"
        exit="exit"
        animate="final"
        initial="initial"
        variants={routeAnimationVariants}>
        <div className="flex h-40 flex-col items-center justify-center gap-3">
          <PackageOpenIcon size={32} className="text-typography-secondary" />
          <p className="text-typography-secondary font-semibold">Não foi possível carregar as informações do usuário.</p>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}
      key="identifier"
      exit="exit"
      animate="final"
      initial="initial"
      variants={routeAnimationVariants}>
      {registerStep === 'user-info' && <UserInfoForm />}
      {registerStep === 'address' && <AddressForm />}
      {registerStep === 'password' && <PasswordForm />}
    </motion.div>
  );
}
