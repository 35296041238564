import { IconButton } from '@eyecarehealth/ds-aquilae-react';
import { RegisterStep, registerStepAtom, UserData, userDataAtom } from '../../atom/auth.atom';
import { useAtom, useAtomValue } from 'jotai';
import { ArrowLeftIcon } from 'lucide-react';

export function GoBack() {
  const userData = useAtomValue<UserData>(userDataAtom);
  const [registerStep, setRegisterStep] = useAtom<RegisterStep>(registerStepAtom);

  if (!userData) return null;

  function goBack() {
    if (registerStep === 'address') {
      setRegisterStep('user-info');
    }

    if (registerStep === 'password') {
      setRegisterStep('address');
    }
  }

  return (
    <IconButton variant="default" onClick={goBack} className="text-typography !rounded-1 flex items-center gap-4 text-sm font-medium">
      <ArrowLeftIcon size={20} className="text-brand-primary" />
    </IconButton>
  );
}
