import BVStyleguide from '@bv/styleguide';
import {
  arcosAsset,
  colaborador1Asset,
  colaborador2Asset,
  colaborador3Asset,
  colaborador4Asset,
  colaborador5Asset,
  colaborador6Asset,
  logoBVAsset,
} from '@public-registration/assets';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegisterStep, registerStepAtom, UserData, userDataAtom, userPreRegisterDataAtom } from '../../atom/auth.atom';
import { ColabAnimated } from './components/ColabAnimated';
import { cn, Progress } from '@eyecarehealth/ds-aquilae-react';

const textSwitchingVariants: Variants = {
  initial: { opacity: 0, y: -24 },
  animate: { opacity: 1, y: 1 },
  exit: { opacity: 0, y: 16 },
};
const colabSwitchingVariants: Variants = {
  initial: { opacity: 0, scale: 1, x: -100 },
  animate: { opacity: 1, scale: 1, x: 0 },
  exit: { opacity: 0, scale: 1, x: 100 },
};
const PHRASES = ['melhor', 'mais claro', 'mais nítido', 'mais fácil'];
const COLAB_IMAGES = [colaborador1Asset, colaborador2Asset, colaborador3Asset, colaborador4Asset, colaborador5Asset, colaborador6Asset];

export function Layout({ children }) {
  const registerStep = useAtomValue<RegisterStep>(registerStepAtom);
  const userPreRegister = useAtomValue(userPreRegisterDataAtom);
  const [selectedPhrase, setSelectedPhrase] = useState(0);
  const [selectedColab, setSelectedColab] = useState(0);

  const progressValue = useMemo(() => {
    if (userPreRegister.isSSOEnabled) {
      if (registerStep === 'user-info') return 15;
      if (registerStep === 'address') return 100;
    } else {
      if (registerStep === 'user-info') return 10;
      if (registerStep === 'address') return 50;
      if (registerStep === 'password') return 100;
    }
    return 0;
  }, [registerStep, userPreRegister]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedPhrase((prev) => {
        if (prev === 3) return 0;
        else return prev + 1;
      });
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedColab((prev) => {
        if (prev === 5) return 0;
        else return prev + 1;
      });
    }, 4500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="left-0 top-0 h-screen w-screen overflow-hidden">
      <div className="container flex h-full max-w-[1140px] 2xl:max-w-[1320px]">
        <div className="flex h-full flex-1 flex-col py-8 sm:pr-10">
          <img src={logoBVAsset} className="h-[49px] w-[134px]" alt="logo" />
          <div className="mb-6 mt-4 space-y-2">
            <Progress value={progressValue} />
            <div className="flex justify-between">
              <span className={cn('text-typography-secondary text-xs', registerStep === 'user-info' && 'text-brand-primary font-semibold')}>
                Dados Pessoais
              </span>
              <span className={cn('text-typography-secondary text-xs', registerStep === 'address' && 'text-brand-primary font-semibold')}>
                Endereço
              </span>
              {!userPreRegister.isSSOEnabled && (
                <span className={cn('text-typography-secondary text-xs', registerStep === 'password' && 'text-brand-primary font-semibold')}>
                  Senha
                </span>
              )}
            </div>
          </div>
          <div className="relative flex flex-1 flex-col gap-10 overflow-y-auto sm:pb-[100px]">{children}</div>
          <p className="text-typography-secondary text-sm">
            Já está cadastrado?{' '}
            <a href="/pre-convite" className="text-brand-primary underline">
              Faça seu login
            </a>
          </p>
        </div>
        <div
          className={cn(
            'rounded-l-4 relative h-full w-[527px] py-8 pl-10 sm:pt-[90px]',
            "before:rounded-l-4 before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-screen before:bg-gradient-to-t before:from-[#006ABC] before:to-[#003056] before:content-['']",
            'hidden sm:block',
          )}>
          <h2 className="font-ubuntu z-1 relative text-4xl font-bold leading-[48px] text-white">
            Enxergando junto com o <span className="text-brand-secondary">Benefício Visão</span>, um futuro{' '}
            <div className="relative">
              <AnimatePresence mode="wait">
                {PHRASES.map(
                  (phrase, index) =>
                    index === selectedPhrase && (
                      <motion.p
                        transition={{
                          type: 'spring',
                          stiffness: 400,
                          damping: 20,
                          times: [0, 3],
                        }}
                        className="absolute"
                        variants={textSwitchingVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key={phrase}>
                        {phrase}.
                      </motion.p>
                    ),
                )}
              </AnimatePresence>
            </div>
          </h2>
          <motion.img
            className="absolute left-[60%] top-[200px] h-[800px] w-[800px] max-w-[unset]"
            src={arcosAsset}
            alt="Arcos azuis"
            transition={{ duration: 1.5 }}
            animate={{ translateX: 0, scaleY: 1, opacity: 1 }}
            initial={{ translateX: 160, scaleY: 0.7, opacity: 0 }}
          />
          <AnimatePresence>
            {COLAB_IMAGES.map((colab, index) => index === selectedColab && <ColabAnimated colab={colab} key={'colab-' + index} />)}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
