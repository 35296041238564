import { atom } from 'jotai';
import { GetUserPreRegisterResponse, UpdateUserDto } from '../services/auth.service';

export type UserData = UpdateUserDto & {};

export type UserPreRegisterData = GetUserPreRegisterResponse;

export type RegisterStep = 'user-info' | 'address' | 'password';

export const userDataAtom = atom({} as UserData);

export const userPreRegisterDataAtom = atom({} as UserPreRegisterData);

export const registerStepAtom = atom<RegisterStep>('user-info');
