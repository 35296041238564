import { ApiQueryBase } from '../../../../types/v2/api';
import { Company } from '../../../../types/v2/company';
import { Address } from '../../../../types/v2/global';
import { Unit } from '../../../../types/v2/unit';
import { User, UserStatus } from '../../../../types/v2/user';
import { api } from './api';

export type GetAllUsersQuery = ApiQueryBase & {
  companyId?: string;
  unitId?: string;
  status?: UserStatus;
};

export type GetUserPreRegisterQuery = {
  otp: string;
};

export type GetUserPreRegisterResponse = User & {
  rootCompany: Company;
  units: Unit[];
};

export type UpdateUserDto = {
  name?: string;
  email?: string;
  birthdate?: string;
  gender?: string;
  password?: string;
  registrationNumber?: string;
  licenseNumber?: string;
  jobPosition?: string;
  address?: Address;
  otp?: string;
  individualIdentifier?: string;
};

type UpdateUserResponse = User;

function getUserPreRegisterService(userId: string, query: GetUserPreRegisterQuery): Promise<GetUserPreRegisterResponse> {
  return api.get(`/v2/auth/user/${userId}`, { params: query });
}

function patchUpdateUserService(userId: string, body: UpdateUserDto): Promise<UpdateUserResponse> {
  return api.patch(`/v2/auth/user/${userId}/register`, body);
}

export { getUserPreRegisterService, patchUpdateUserService };
