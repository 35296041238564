import { Button, EInput, Form, FormInput, Separator } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { registerStepAtom, UserData, userDataAtom, UserPreRegisterData, userPreRegisterDataAtom } from '../../../../atom/auth.atom';
import { UpdateUserDto } from '../../../../services/auth.service';
import { SearchIcon } from 'lucide-react';
import bvUtils from '@bv/utils';
import { SelectOption } from '../../../../../../../types/v2/global';
import { format } from 'date-fns';

const GENDER_OPTIONS: SelectOption[] = [
  {
    label: 'Masculino',
    value: 'M',
  },
  {
    label: 'Feminino',
    value: 'F',
  },
];

export const formSchema = z.object({
  name: z.string().min(1, 'Digite o nome do colaborador'),
  individualIdentifier: z.string({ required_error: 'Digite o documento de identificação' }).min(1, 'Digite o documento de identificação'),
  email: z.string().optional(),
  registrationNumber: z.string().optional(),
  cellphone: z.string({ required_error: 'Número de celular é obrigatório' }).refine((value) => /^\(\d{2}\) \d{5}-\d{4}$/.test(value), {
    message: 'Número de celular inválido',
  }),
  licenseNumber: z.string().optional(),
  jobPosition: z.object(
    {
      label: z.string().min(1, 'Selecione o cargo'),
      value: z.string().min(1, 'Selecione o cargo'),
    },
    {
      invalid_type_error: 'Selecione o cargo',
    },
  ),
  birthdate: z.date({
    invalid_type_error: 'Data de nascimento inválida',
    required_error: 'Data de nascimento é obrigatória',
  }),
  gender: z.string().min(1, 'Escolha o sexo biológico'),
  acceptedTerms: z.boolean().refine((value) => value === true, { message: 'Você precisa aceitar os termos para continuar' }),
});

export function UserInfoForm() {
  const userPreRegister = useAtomValue<UserPreRegisterData>(userPreRegisterDataAtom);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const setRegisterStep = useSetAtom(registerStepAtom);
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const { jobPositionsOptions, promise: fetchJobPositionsPromise } = bvUtils.hooks.useJobPositionsForCombo();

  function createUserInfo(data: z.infer<typeof formSchema>) {
    if (!bvUtils.zod.validateIdentifierByCountryUtil(data.individualIdentifier!, userPreRegister.countryCode)) {
      return methods.setError('individualIdentifier', {
        message: 'Documento de identificação inválido',
      });
    }
    if (userPreRegister.rootCompany?.inviteValidationType === 'EMAIL' && !data.email) {
      return methods.setError('email', {
        message: 'O e-mail é obrigatório pois a empresa matriz utiliza esse dado para validar os convites',
      });
    }

    if (userPreRegister.rootCompany?.inviteValidationType === 'REGISTRATION_NUMBER' && !data.registrationNumber) {
      return methods.setError('registrationNumber', {
        message: 'A matrícula é obrigatória pois a empresa matriz utiliza esse dado para validar os convites',
      });
    }

    if (userPreRegister.roles.includes('doctor') && !data.licenseNumber) {
      return methods.setError('licenseNumber', {
        message: 'O CRM/COREN é obrigatório',
      });
    }

    const objToSend: UpdateUserDto = {
      name: data.name,
      email: data.email,
      birthdate: format(data.birthdate, 'yyyy-MM-dd'),
      gender: data.gender,
      registrationNumber: data.registrationNumber,
      licenseNumber: data.licenseNumber,
      jobPosition: data.jobPosition.value,
      individualIdentifier: data.individualIdentifier,
    };

    setUserData({ ...userData, ...objToSend });

    setRegisterStep('address');
  }

  useEffect(() => {
    if (userPreRegister) {
      methods.setValue('name', userPreRegister.name);
      methods.setValue('email', userPreRegister.email);
      methods.setValue('cellphone', userPreRegister.cellphone);
      methods.setValue('registrationNumber', userPreRegister?.registrationNumber ?? '');
      methods.setValue('individualIdentifier', userPreRegister.individualIdentifier ?? '');
      methods.setValue('licenseNumber', userPreRegister.licenseNumber ?? undefined);
    }
  }, [userPreRegister]);

  return (
    <div className="pb-4">
      <div className="mb-10">
        <h4 className="font-ubuntu text-typography mb-3 text-xl font-semibold">Complete seu cadastro no BV</h4>
        <p className="text-typography-secondary">
          Olá, <strong>{userPreRegister.name}</strong>
        </p>
        <p className="text-typography-secondary">Agora você faz parte do melhor benefício visual do mundo. Bem-vindo(a) nossa tribo.</p>
      </div>
      <Form ctx={methods} onSubmit={createUserInfo}>
        <FormInput name="name" label="Nome" placeholder="Digite o nome do usuário" type={EInput.TEXT} />
        <div className="flex flex-col md:flex-row gap-2">
          <FormInput name="birthdate" label="Data de Nascimento" placeholder="dd/mm/aaaa" type={EInput.DATEPICKER} />
          <FormInput name="gender" label="Sexo Biológico" options={GENDER_OPTIONS} type={EInput.SELECT} />
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <FormInput name="email" label="E-mail" placeholder="Digite o e-mail do usuário" disabled={!!userPreRegister.email} type={EInput.EMAIL} />
          <FormInput name="cellphone" label="Telefone/Celular" placeholder="(99) 99999-9999" type={EInput.MASK} mask="(##) #####-####" />
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <FormInput
            name="individualIdentifier"
            label="Documento de identificação"
            placeholder="Digite o documento de identificação"
            type={EInput.TEXT}
          />
          <FormInput name="registrationNumber" label="Matrícula" placeholder="Digite a matrícula (opcional)" type={EInput.TEXT} />
        </div>
        {userPreRegister.roles.includes('doctor') && (
          <FormInput name="licenseNumber" label="CRM/COREN" placeholder="Digite o documento CRM/COREN" type={EInput.TEXT} />
        )}

        <Separator className="my-5 bg-gray-300" />

        <FormInput
          type={EInput.SELECT_SEARCH_ASYNC}
          placeholder="Busque o cargo"
          inputIcon={<SearchIcon size={20} className="text-brand-primary" />}
          loadOptions={fetchJobPositionsPromise}
          options={jobPositionsOptions}
          name="jobPosition"
          label="Cargo"
          popoverClassName="w-[500px]"
          canCreateOption={true}
        />
        <FormInput
          className="!mt-4 flex flex-row-reverse !justify-end gap-2"
          name="acceptedTerms"
          id="acceptedTerms"
          label={
            <p className="mb-0 leading-6">
              Li e estou de acordo com os{' '}
              <a href="https://eyecarehealth.com.br/termos-e-politicas/termos-de-uso" target="_blank">
                Termos de uso
              </a>{' '}
              e{' '}
              <a href="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" target="_blank">
                Aviso de privacidade
              </a>
              .
            </p>
          }
          type={EInput.CHECKBOX}
        />

        <Button type="submit" className="mt-6 w-full" size="lg">
          Continuar
        </Button>
      </Form>
    </div>
  );
}
