import { Button, Form } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Address } from '../../../../../../../types/v2/global';
import { useState } from 'react';
import bvUtils from '@bv/utils';
import { patchUpdateUserService, UpdateUserDto } from '../../../../services/auth.service';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { registerStepAtom, UserData, userDataAtom, UserPreRegisterData, userPreRegisterDataAtom } from '../../../../atom/auth.atom';
import { FormAddress } from '@public-registration/components/FormAddress';
import { useMutation } from '@tanstack/react-query';
import { PATCH_UPDATE_USER } from '@public-registration/constants/queries';
import * as singleSpa from 'single-spa';
import { GoBack } from '@public-registration/components/GoBack';

const formSchema = z.object({
  zipCode: z.string().min(9, { message: 'Campo CEP inválido' }),
  street: z.string().min(1, { message: 'Campo rua é obrigatório' }),
  neighbourhood: z.string().min(1, { message: 'Campo bairro é obrigatório' }),
  city: z.string().min(1, { message: 'Campo cidade é obrigatório' }),
  state: z.string().min(1, { message: 'Campo estado é obrigatório' }),
  streetNumber: z.string().optional(),
  complement: z.string().optional(),
});

export function AddressForm() {
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const userPreRegister = useAtomValue<UserPreRegisterData>(userPreRegisterDataAtom);
  const setRegisterStep = useSetAtom(registerStepAtom);
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const [address, setAddress] = useState<Omit<Address, 'number' | 'country' | 'complement'>>();
  const searchParams = new URLSearchParams(window.location.search);

  const userId = searchParams.get('_i');
  const otp = searchParams.get('otp');

  const { mutate: updateUser, isPending: isUserUpdating } = useMutation({
    mutationKey: [PATCH_UPDATE_USER],
    mutationFn: async (data: UpdateUserDto) => patchUpdateUserService(userId, { ...data, otp }),
    onSuccess: (data) => {
      bvUtils.toastHandler({ type: 'success', message: `${userPreRegister.name} seu cadastro foi concluído.` });
      singleSpa.navigateToUrl('/login');
    },
  });

  function createAddress(data: z.infer<typeof formSchema>) {
    // if (!address) {
    //   bvUtils.toastHandler({ type: 'error', message: 'Endereço é obrigatório!' });
    //   throw new Error('Endereço é obrigatório!');
    // }

    console.log('Atualizando endereço do usuário');
    const addressInfo: Address = {
      city: data.city,
      neighbourhood: data.neighbourhood,
      state: data.state,
      street: data.street,
      zipCode: data.zipCode,
      placeId: address?.placeId,
      location: address?.location,
      number: data.streetNumber,
      complement: data.complement,
      country: 'BRA',
    };

    setUserData({ ...userData, address: addressInfo });

    if (userPreRegister.isSSOEnabled) {
      updateUser({ ...userData, address: addressInfo });
    } else {
      console.log('Passou aqui');
      setRegisterStep('password');
    }
  }
  return (
    <div>
      <div className="mb-10 flex gap-3">
        <GoBack />
        <h4 className="font-ubuntu text-typography mb-3 text-xl font-semibold">Preencha seu endereço</h4>
      </div>
      <Form ctx={methods} onSubmit={createAddress}>
        <FormAddress address={address!} setAddress={setAddress} methods={methods} />
        <Button type="submit" size="lg" className="mt-6 w-full" loading={isUserUpdating}>
          {userPreRegister.isSSOEnabled ? 'Concluir cadastro' : 'Continuar'}
        </Button>
      </Form>
    </div>
  );
}
